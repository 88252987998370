<template>
    <div :is="tag" class="talk_image">
      <iframe
          v-if="fileType==='youtube' && ready"
          width="auto"
          height="100%"
          :src="attachment | toEmbedVideoUrl"
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      <a v-if="fileType==='image' && !isDigitalItem" @click="$emit('modalOpenPreview', attachment)" class="image">
        <img v-if="attachment" :src="attachment | thumbnail(width) " alt="">
      </a>

      <a v-if="fileType=='pdf' && !isDigitalItem" @click="$emit('modalOpenPreview', attachment)" class="image">
        <object v-if="attachment" :data="attachment | thumbnail(width)" type="application/pdf" style="height:100%; width:100%;"></object>
        <div @click.stop=""  @click="$emit('modalOpenPreview', attachment)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2;"></div>
      </a>

      <template v-if="fileType==='video'">
        <a v-if="attachment && !isDigitalItem && !ready" @click="$emit('modalOpenPreview', attachment)" class="image">
          <img :src="attachment | thumbnail(width) " alt="">
        </a>
        <img class="mosaic" v-if="attachment && isDigitalItem && !ready && !haveBoughtDigitalContents" :src="attachment | thumbnail(width) " alt="">
        <a v-if="attachment && isDigitalItem && !ready && haveBoughtDigitalContents" @click="$emit('modalOpenPreview', digitalSource)" class="image">
          <img :src="attachment | thumbnail(width) | omit_mosaic " alt="">
        </a>
        <svg xmlns="http://www.w3.org/2000/svg" v-if="!ready"
             viewBox="0 0 512 512"
             class="play-button" xml:space="preserve">
            <path class="st0" d="M256,0C114.625,0,0,114.625,0,256c0,141.374,114.625,256,256,256c141.374,0,256-114.626,256-256
              C512,114.625,397.374,0,256,0z M351.062,258.898l-144,85.945c-1.031,0.626-2.344,0.657-3.406,0.031
              c-1.031-0.594-1.687-1.702-1.687-2.937v-85.946v-85.946c0-1.218,0.656-2.343,1.687-2.938c1.062-0.609,2.375-0.578,3.406,0.031
              l144,85.962c1.031,0.586,1.641,1.718,1.641,2.89C352.703,257.187,352.094,258.297,351.062,258.898z"></path>
          </svg>
        <a v-if="ready" @click="checkIfImageExists(attachment)" class="image">
          <div>動画ファイル変換中<br>
          しばらくお待ちください<br>（クリックで更新）</div>
        </a>
      </template>
      <template v-if="fileType==='image'">
        <img class="mosaic" v-if="attachment && isDigitalItem && !haveBoughtDigitalContents" :src="attachment | thumbnail(width) " alt="">
        <a v-if="attachment && isDigitalItem && haveBoughtDigitalContents" @click="$emit('modalOpenPreview', digitalSource)" class="image">
          <img :src="digitalSource" alt="">
        </a>
      </template>
      <audio v-if="fileType==='audio' && ready" :src="attachment" controls="controls">
        音声を再生するには、audioタグをサポートしたブラウザが必要です。
      </audio>

      <a v-if="fileType==='youtube' && !ready" @click="ready = true" class="thumb_movie">
        <img v-if="attachment" :src="attachment | toPosterUrl" alt="">
        <img src="@/assets/img/talk/thumb_movie.png" alt="再生アイコン" class="thumb_movie_icon">
      </a>
      <a v-if="fileType==='audio' && !ready" @click="ready = true">
        <img src="@/assets/img/talk/thumb_audio.png" alt="音声">
      </a>
    </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'AttachmentThumb',
  components: {  },
  props: {
    attachmentsLength: Number,
    attachment: String,
    whole: Boolean,
    tag: {
      type: String,
      default: 'div'
    },
    size: String,
    isDigitalItem: Boolean,
    haveBoughtDigitalContents: Boolean,
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    fileType () {
      if (this.attachment.toLowerCase().endsWith('.pdf')) {
        return 'pdf';
      }
      if (/^https?:\/\/(www\.)?youtu\.?be(\.com)?/.test(this.attachment)) {
        return 'youtube';
      } else if (/\.audio\.\w+$/.test(this.attachment)) {
        return 'audio';
      } else if (/\.image\.\w+$/.test(this.attachment)) {
        return 'image';
      } else if (/\.video\./.test(this.attachment)) {
        return 'video';
      } 
      return 'image';
    },
    width () {
      if (this.size) return this.size;
      //return this.whole ? '335:160' : '165:80';
      return this.whole ? '555:400:middle' : '275:200:middle';
    },
    digitalSource () {
      const matches = this.attachment.match(/\/storage\/uploads\/talk_room\/(.*)/);
      if (!matches) return;
      const id = matches[1].replace('.mosaic.', '.');
      const time = moment().unix();
      const md5 = require("md5")
      const secret = 'message';
      const checksum = md5(id + time + secret);
      return process.env.VUE_APP_API_ROOT + `../image.php?id=${id}&time=${time}&checksum=${checksum}`;
    }
  },
  filters: {
    toEmbedVideoUrl(url) {
      let id;
      const found = url.match(/https:\/\/youtu\.be\/([\w_.!*'()-]{11})/i);
      const found_shorts = url.match(/https:\/\/youtube\.com\/shorts\/([\w_.!*'()-]{11})/i);
      if (found && found[1]) {
        id = found[1];
      } else if (found_shorts && found_shorts[1]) {
        id = found_shorts[1];
      } else {
        const getParameterByName = (name, url) => {
          let queryString = url.split('?');
          if(queryString.length >= 2){
            let paras = queryString[1].split('&');
            for(let i = 0; i < paras.length; i++){
              let eachPara = paras[i].split('=');
              if(eachPara[0] == name) return eachPara[1];
            }
          }
        }
        id = getParameterByName('v', url);
      }
      return 'https://www.youtube.com/embed/'+id;
    },
    toPosterUrl(url) {
      let id;
      const found = url.match(/https:\/\/youtu\.be\/([\w_.!*'()-]{11})/i);
      const found_shorts = url.match(/https:\/\/youtube\.com\/shorts\/([\w_.!*'()-]{11})/i);
      if (found && found[1]) {
        id = found[1];
      } else if (found_shorts && found_shorts[1]) {
        id = found_shorts[1];
      } else {
        const getParameterByName = (name, url) => {
          let queryString = url.split('?');
          if (queryString.length >= 2) {
            let paras = queryString[1].split('&');
            for (let i = 0; i < paras.length; i++) {
              let eachPara = paras[i].split('=');
              if (eachPara[0] == name) return eachPara[1];
            }
          }
        }
        id = getParameterByName('v', url);
      }
      return 'https://i.ytimg.com/vi/' + id + '/mqdefault.jpg';
    },
    omit_mosaic(val) {
      return val.replace('.mosaic', '');
    },
  },
  methods: {
    checkIfImageExists(url) {
      const check = (url) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => resolve(url);
          img.onerror = () => reject(url);
        });
      }
      check(url)
          .then(() => {
            this.ready = false;
          })
          .catch(() => {
            this.ready = true;
          });
    }
  },
  created () {
    if (this.fileType === 'video') {
      this.checkIfImageExists(this.attachment)
    }
  }
}
</script>

<style lang="scss" scoped>
  div.talk_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-align: center;
      img, audio, iframe, video {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .pdf-canvas {
    width: 100px;
    height: 100px;
    /* 他のCSSプロパティ... */
  }

  li.talk_image {
    text-align: center;
    img {
      height: 100%;
    }
  }
  .thumb_movie {
    position: relative;
  }
  .thumb_movie .thumb_movie_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: 0;
    padding: 0;
  }
  .image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 64px;
    height: 64px;
    fill: #1ba79a;
    z-index:1;
    opacity: 0.8;
    pointer-events: none;
  }
  // https://stackoverflow.com/a/14068216
  img.mosaic {
    image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
    image-rendering: -moz-crisp-edges;          /* Firefox                        */
    image-rendering: -o-crisp-edges;            /* Opera                          */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
    image-rendering: pixelated; /* Chrome */
    image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
    -ms-interpolation-mode: nearest-neighbor;   /* IE8+                           */
  }
</style>
