<template>
  <div class="">
    <div v-for="(cart, index) in order.carts" :key="cart.id">
      <div class="item">
        <ul class="col2 mb_10" v-if="index === 0">
          <li class="col2L buy_date">
            <img src="@/assets/img/mypage/icon_calendar.png" alt="カレンダーアイコン">
            購入日：{{ order.created_at | formatDate }}
          </li>
          <li class="col2R">
            <span class="mark_done_on" v-if="!order.send_at">注文済</span>
            <span class="mark_done_off" v-if="order.send_at">発送済</span>
          </li>
        </ul>
        <ul class="col2">
          <li class="col2L">
            <div class="item_photo">
              <img :src="cart.item.item_photos.length ? cart.item.item_photos[0].image : '' | imageUrl" alt="商品画像">
            </div>
          </li>
          <li class="col2R" style="width: 100%;">
            <div class="item_title">{{ cart.item.name }}</div>
            <div class="item_text">
              {{ cart.item.detail | truncate }}</div>
            <ul class="col2 mb_10">
              <li class="item_shipping">送料込</li>
              <li class="item_price">{{ cart.item.price | currency }}<span>税込</span></li>
            </ul>
            <div class="item_volume">数量：{{ cart.value }}</div>
          </li>
        </ul><!--.col2-->
      </div><!-- .item -->
      <hr class="item_hr mtb_20" v-if="index + 1 < order.carts.length">
    </div>
    <div class="item_total">
      <div class="item_shipping">合計(税込)</div>
      <div class="item_price">{{ order.price | currency }}</div>
    </div>
    <div class="item_comment" v-if="order.message">{{ order.message }}</div>
    <hr class="item_hr_w100 mtb_20">
  </div><!-- .item -->
</template>

<script>
import moment from 'moment';
export default {
  props: {
    order: Object,
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    },
    formatTime (val) {
      return moment(val).format('HH:mm');
    }
  },
  computed: {
    isSend() {
      return this.order.send_at;
    }
  }
}
</script>
