<template>
  <div class="user_sns">
    <a v-if="user.twitter" :href="`https://twitter.com/${user.twitter}`" target="_blank">
      <img src="@/assets/img/logo_twitter.png" alt="Twitterロゴ">
    </a>
    <a v-if="user.facebook" :href="`https://www.facebook.com/${user.facebook}`" target="_blank">
      <img src="@/assets/img/logo_facebook.png" alt="Facebookロゴ">
    </a>
    <a v-if="user.instagram" :href="`https://instagram.com/${user.instagram}/`" target="_blank">
      <img src="@/assets/img/logo_instagram.png" alt="Instagramロゴ">
    </a>
  </div>
</template>

<script>
export default {
  name: 'SnsLink',
  props: {user: Object},
}
</script>
