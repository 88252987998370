<template>
  <ul>
    <AttachmentThumb
        v-for="(attachment, index) in attachments"
        tag="li"
        :key="`attachment_${index}`"
        size="140"
        :attachment="attachment"
        @modalOpenPreview="$emit('modalOpenPreview', attachment)">
    </AttachmentThumb>
  </ul>
</template>

<script>
import AttachmentThumb from '@/components/TalkRoom/AttachmentThumb';
export default {
  name: 'AppAlbum',
  components: { AttachmentThumb },
  props: {
    album: Array,
  },
  computed: {
    attachments () {
      const attachments = this.album.slice(0, 12);
      // 4の倍数に足らなければ穴埋めする
      const len = attachments.length;
      if (len % 4 !== 0) {
        for (let i = 0; i < 4 - len % 4; i++) {
          attachments.push('');
        }
      }
      return attachments;
    }
  }
}
</script>

<style>
  #mypage ul.list_album li a {
    width: 100%;
    height: 100%;
  }
  #mypage ul.list_album li a img{
    width: 100%;
    height: 100%;
    min-width: 0 !important;
  }
</style>
