<template>
  <div class="acd">
    <input id="check2" type="checkbox" class="acd-hidden" @change="open">
    <label for="check2" class="acd-open">購入履歴</label>
    <label class="acd-close" v-if="!user">
      <MyOrder :order="order" v-for="order in orders" :key="order.id"></MyOrder>
      <div v-if="!orders || orders.length === 0">購入履歴がありません</div>
    </label>
    <label class="acd-close" v-if="user">
      <ul class="">
        <template v-for="order in orders">
          <li v-for="cart in order.carts" :key="cart.id">
            （{{  cart.item.name }}）を購入しました。（{{ cart.item.name.createdat | formattedDate }}）
<!--            <img :src="cart.item.item_photos.length ? cart.item.item_photos[0].image : '' | imageUrl" alt="商品画像" class="item_photo">-->
          </li>
        </template>
        <div v-if="!orders || orders.length === 0 || !orders[0].carts || orders[0].carts.length === 0">購入履歴がありません</div>
      </ul>
    </label>
  </div>
</template>

<script>
import MyOrder from '@/components/MyOrder';
import moment from 'moment';
export default {
  components: { MyOrder },
  props: {
    user: {
      type: Object,
      default: null,
    },
    talkRoom: {
      type: Object,
      default: null,
    }
  },
  data () {
    return {
      orders: null
    }
  },
  filters: {
    formattedDate (val) {
      return moment(val).format('YYYY/MM/DD');
    },
  },
  methods: {
    async open () {
      let response;
      if (this.orders === null) {
        if (this.user === null) {
          response = await this.$http.get('me/orders');
          this.orders = response.data;
        } else {
          response = await this.$http.get(`user/${this.user.id}/orders?artist_id=${this.talkRoom.artist_id}`);
          this.orders = response.data;
        }

      }
    },
  }
}
</script>

<style lang="scss" scoped>
ul {
  text-align: left;
  list-style: disc;
  margin-left: 2rem;
}
//.item_photo {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  object-fit: cover;
//  object-position: 50% 50%;
//}
</style>
