<template>
  <div class="acd">
    <input id="check3" type="checkbox" class="acd-hidden" @change="open">
    <label for="check3" class="acd-open">デジコン</label>
    <label class="acd-close">
      <ul class="list_digicon" v-if="user">
        <li v-for="digital_content in digital_contents" :key="digital_content.id">
          デジコン({{ digital_content.created_at | formattedDate }}投稿)を購入しました
        </li>
        <li v-if="!digital_contents || digital_contents.length === 0">購入履歴がありません</li>
      </ul>
      <ul class="list_album" v-if="!user">
        <li v-for="digital_content in digital_contents" :key="digital_content.id">
          <img :src="digital_content.url | digitalSource" alt="" v-if="digital_content.url"
               @click="modalOpenPreview($options.filters.digitalSource(digital_content.url))" style="cursor:pointer">
        </li>
        <li v-if="!digital_contents || digital_contents.length === 0">購入履歴がありません</li>
      </ul>
    </label>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  components: { },
  props: {
    user: {
      type: Object,
      default: null,
    },
    talkRoom: {
      type: Object,
      default: null,
    }
  },
  data () {
    return {
      digital_contents: null
    }
  },
  filters: {
    formattedDate (val) {
      return moment(val).format('YYYY/MM/DD HH:mm');
    },
    digitalSource (val) {
      const matches = val.match(/\/storage\/uploads\/talk_room\/(.*)/);
      if (!matches) return;
      const id = matches[1].replace('.mosaic.', '.');
      const time = moment().unix();
      const md5 = require("md5")
      const secret = 'message';
      const checksum = md5(id + time + secret);
      return process.env.VUE_APP_API_ROOT + `../image.php?id=${id}&time=${time}&checksum=${checksum}`;
    }
  },
  methods: {
    async open () {
      if (this.digital_contents === null) {
        const url = (this.talkRoom) ? `me/digital_contents?artist_id=${this.talkRoom.artist_id}` : 'me/digital_contents';
        const response = await this.$http.get(url);
        this.digital_contents = response.data;
      }
    },
    modalOpenPreview (url) {
      this.$emit('modalOpenPreview', url)
    }
  }
}
</script>
